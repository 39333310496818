@tailwind base;
@tailwind components;
@tailwind utilities;

.topicButton {
    background-color: black;
    color: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

/* Unchecked state */
.topicSelectButton {
    appearance: none;
    height: 1rem; /* h-4 */
    width: 1rem; /* w-4 */
    border: 1px solid white; /* border border-white */
    border-radius: 0.125rem; /* rounded-sm */
    background-color: transparent; /* bg-transparent */
    margin-left: auto;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s; /* Smooth transition */
}

/* Checked state */
.topicSelectButton:checked {
    background-color: transparent; /* Ensure the background stays transparent */
    border-color: white; /* Keep the border color white */
}

/* Tick mark */
.topicSelectButton:checked::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 4px;
    width: 6px;
    height: 12px;
    border-right: 2px solid white; /* White tick */
    border-bottom: 2px solid white; /* White tick */
    transform: rotate(45deg);
}

/* Ensure border visibility on hover and focus */
.topicSelectButton:hover, 
.topicSelectButton:focus {
    border-color: white;
}

.pagebreak {
    page-break-before: always; /* Forces a page break before this element in print */
}

hr.solid {
    border-top: 3px solid black;
}

.hidden-to-print {
    display: none;
}

@media print {
    .hidden-to-print {
        display: block !important;
        visibility: visible !important;
    }
}

html {
    scroll-behavior: smooth;
}